import "./component.css";

function logIn(props) {
    return (
        <form className="login-form" action="/list">
            <input type="email" className="input" placeholder="Email"
                value={props.email} onChange={(e)=>props.setEmail(e.target.value)} required />
            <input type="password" className="input" placeholder="Password"
                value={props.pass} onChange={(e)=>props.setPass(e.target.value)} required />
            <button className="log" onClick={props.btn}>LOGIN</button>
            <button className="signUp" onClick={props.btnTwo}>Google</button>
        </form>
    );
}

export default logIn;