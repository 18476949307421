import fire from "../firebase";
import "./component.css";

function list(props) {
    const icon = {
        delete : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(255, 0, 0)" width="25px" height="25px"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>,
    }
    return (
        <div>
            <ul className="ul container">
                <li>
                    <span>{ props.item.list }</span>
                    <span>
                        <button className="spanTwo" onClick={ () => {
                            fire.firestore().collection(props.name).doc(props.item.id).delete();
                            } }>
                            { icon.delete }
                        </button>
                    </span>
                </li>
            </ul>
        </div>
    );
}

export default list;