import { useEffect, useState } from 'react';
import './App.css';
import List from "./componenets/list";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import fire from "./firebase";
import Login from "./componenets/login";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

function App() {
  const [list,setList] = useState([])
  const [input, setInput] = useState("");
  // for login page
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [name, setName] = useState('u');
  const [off, on] = useState('');
  const [userdata, setUserdata] = useState('');

  fire.auth().onAuthStateChanged((user) => {
    if(user){
      on(user);
      setName(user.email)
      setUserdata(user.email)
    }
    else {
      fire.auth().signOut();
      on('');
    }
  });

  const google = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    fire.auth().signInWithPopup(provider)
      .then((result)=> {
        const mail = result.user.email
        const username = result.user.displayName
        setUserdata(username)
        setName(mail)
      })
      .catch((err)=>{
        const errcode = err.code;
        const errmsg = err.message;
        window.alert(errcode)
        console.log(errmsg)
      })
  }
  
  useEffect(() => {
    fire.firestore().collection(name).orderBy('timestamp','desc').onSnapshot(snap => {
      setList(snap.docs.map(doc => ({
        id : doc.id,
        list : doc.data().list,
      })))
    })
  },[name] )

  const toList = (event) => {
    event.preventDefault();
    fire.firestore().collection(name).add({
      list : input,
      nm : userdata,
      timestamp : firebase.firestore.FieldValue.serverTimestamp(),
    })
    setInput("");
  }

  const handleLogin = (e) => {
    e.preventDefault();
    fire.auth().signInWithEmailAndPassword(email, pass)
      .then((u) => {
        setName(u.user.email)
        console.log("You are logged in now")
      })
      .catch((err)=>{
        const errcode = err.code;
        const errmsg = err.message;
        window.alert(errmsg);
        console.log(errcode);
      })
  }

  const lst = () => {
    return (
      <div>
        <form className="add container">
          <input type="text" className="itemname" onChange={e => setInput(e.target.value)} value={input} placeholder="ITEM to ADD" />
          <button type="submit" disabled={!input} className="submit" onClick={toList} >ADD</button>
        </form>

        <div className="list">
          {list.map((i)=> <List item={i} key={i.id} name={name} />)}
        </div>
        <h2 className='userdata'>{userdata}</h2>
        <button className="signOut" onClick={()=> fire.auth().signOut()}>SIGN OUT</button>
      </div>
    )
  }

  

  return (
    <div className="App">
      <Router>
        <Switch>
          {(!off)
            ? <Route path="/" exact>
                <Login email={email} setEmail={setEmail}
                  pass={pass} setPass={setPass}
                  btn={handleLogin} btnTwo={google} />
              </Route>
            : <Route path="/" exact>{lst}</Route>
          }
          
        </Switch>
      </Router>
      
    </div>
  );
}

export default App;
