import firebase from "firebase/app";

const fire = firebase.initializeApp({
    apiKey: "AIzaSyCqCY10pK-fmdMygT_DrBbE5uE7IDentVE",
    authDomain: "my-first-project-3e558.firebaseapp.com",
    projectId: "my-first-project-3e558",
    storageBucket: "my-first-project-3e558.appspot.com",
    messagingSenderId: "267916136284",
    appId: "1:267916136284:web:e5abcbb90b2e50ff373b99"
});

export default fire;